import React from 'react';
import { FieldValues, FieldPath } from 'react-hook-form';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers';
import '@mui/lab';
import { FormInputProps } from './types';
import moment, { Moment } from 'moment';
import { TextFieldProps, SxProps, Theme } from '@mui/material';
import { MuiTextFieldProps } from '@mui/lab/internal/pickers/PureDateInput';
import { useMuiFormController } from './utils';
import { FormFieldSkeleton } from './FormFieldSkeleton';

export interface FormDatePickerProps<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>> 
    extends FormInputProps<Date | null, TFieldValues, TName>,
    Omit<DatePickerProps<Moment,Moment>, "name" | "value" | "onChange">
{
	/** Intercepts the value on change so it can be modified before being returned. (undefined means the value will not be changed) */
	onChange?: (value: Moment | null) => Moment | Date | null | undefined | void;
	/** Input Size */
	size?: TextFieldProps["size"];
	/** sx Styling */
	sx?: SxProps<Theme>;
	/** Input Renderer */
    renderInput: (props: MuiTextFieldProps) => React.ReactElement;
}

const FormDatePicker = <TFieldValues extends FieldValues,  TName extends FieldPath<TFieldValues>>({
        name, control, rules, shouldUnregister, defaultValue,
        loading, required, noErrorText, dependents, onBlur,
		onChange: onValueChange, sx, renderInput,
        ...datePickerProps
    }: FormDatePickerProps<TFieldValues, TName>
) => {
    //use form controller hook
    const {
        value, onChange, ref,
        isRequired, isLoading, isViewOnly, hasError, helperText, handleBlur,
		context: { size }
    } = useMuiFormController({
        name, control, rules, shouldUnregister, defaultValue,
        loading, required, noErrorText, dependents, onBlur,
    });

    //render loading
    if (isLoading) return <FormFieldSkeleton {...datePickerProps} size={datePickerProps.size ?? size} sx={sx}/>;

    //render DatePicker 
    return (
        <DatePicker
            {...datePickerProps}
            value={value === "" ? null : moment(value)}
            onChange={(newVal) => {
				const changedVal = onValueChange ? onValueChange(newVal) : newVal;
				if (changedVal !== undefined) onChange(changedVal ? changedVal instanceof Date ? changedVal : changedVal.toDate() : changedVal);
			}}
            disabled={isViewOnly || datePickerProps.disabled}
            renderInput={(params) => renderInput({ 
                ...params,
                name: name,
				size: datePickerProps.size ?? size,
                helperText: helperText, 
                error: hasError, 
                ref: ref, 
                onBlur: handleBlur,
                required: isRequired,
				sx: sx,
            })}
        />
    );
};

export { FormDatePicker };