import { Home, PeopleAlt, DirectionsCar, DoorFrontOutlined, Assignment, Sync, Send, CalendarMonth, Notifications, EventAvailable } from '@mui/icons-material';
import { MenuOption, MenuCategory } from './types';
import { UserRole } from "@imas/types";
import { PAGES } from '../pages';

export const HOME: MenuCategory = {
    name: "Home",
	url: PAGES.HOME(),
    options: [
        new MenuOption({ name: "Dashboard", url: PAGES.DASHBOARD(), requiredRole: UserRole.User, mobileSafe: true, icon: <Home/> }),
        new MenuOption({ name: "Employee Directory", url: PAGES.HOME.EMPLOYEE_DIRECTORY(), requiredRole: UserRole.User, mobileSafe: true, icon: <PeopleAlt/> }),
        new MenuOption({ name: "Sync Directory", url: PAGES.HOME.SYNC_EMPLOYEE_INFO(), requiredRole: UserRole.User, mobileSafe: true, icon: <Sync/>, mobileOnly: true }),
		new MenuOption({ name: "Vehicles", url: PAGES.HOME.VEHICLES(), requiredRole: UserRole.User, mobileSafe: true, icon: <DirectionsCar/> }),
        new MenuOption({ name: "Door Access", url: PAGES.HOME.DOOR_ACCESS(), requiredRole: UserRole.User, mobileSafe: true, icon: <DoorFrontOutlined/> }),
        new MenuOption({ name: "Notifications", url: PAGES.HOME.NOTIFICATIONS(), requiredRole: UserRole.User, mobileSafe: true, mobileOnly: true, icon: <Notifications/> }),
        //new MenuOption({ name: "Orginazation Chart", url: PAGES.HOME.ORGCHART(), requiredRole: UserRole.User})
        //{name: "Address Search", path: "/home/address-search"},
        //{name: "Document Search", path: "/home/document-search"},
        //{name: "File Directory", path: "/home/file-directory"},
        //{name: "Maps/Directions", path: "/home/maps-and-directions"},
    ],
};
    
export const CLIENTS_AND_CONTACTS: MenuCategory = {
    name: "Clients & Contacts",
	url: PAGES.CLIENTS_AND_CONTACTS(),
    options: [
        new MenuOption({ name: "Search", url: PAGES.CLIENTS_AND_CONTACTS.SEARCH(), requiredRole: UserRole.User }),
        new MenuOption({ name: "Contact Lists", url: PAGES.CLIENTS_AND_CONTACTS.CONTACT_LISTS(), requiredRole: UserRole.PowerUser }),
        //{name: "Lead Tracker", path: "lead-tracker"},
    ],
};

export const PROPOSALS: MenuCategory = {
    name: "Proposals",
	url: PAGES.PROPOSALS(),
    options: [
        new MenuOption({ name: "Proposal Tracking", url: PAGES.PROPOSALS.TRACKING(), requiredRole: UserRole.PowerUser }),
        new MenuOption({ name: "Fee Schedule", url: PAGES.PROPOSALS.FEE_SCHEDULE(), requiredRole: UserRole.PowerUser }),
        new MenuOption({ name: 'Projects', url: PAGES.PROPOSALS.PROJECTS(), requiredRole: UserRole.PowerUser }),
        new MenuOption({ name: "Scope Templates", url: PAGES.PROPOSALS.SCOPE_TEMPLATES(), requiredRole: UserRole.PowerUser }),
        new MenuOption({ name: "Report Templates", url: PAGES.PROPOSALS.REPORT_TEMPLATES(), requiredRole: UserRole.PowerUser }),
        new MenuOption({ name: "Weld Tests", url: PAGES.PROPOSALS.WELD_TESTS(), requiredRole: UserRole.User }),
    ],
};

export const SERVICE_ORDERS: MenuCategory = {
    name: "Service Orders",
	url: PAGES.SERVICE_ORDERS(),
    options: [
        new MenuOption({ name: "S.O. Tracking", url: PAGES.SERVICE_ORDERS.TRACKING(), requiredRole: UserRole.User }),
        new MenuOption({ name: "Lab Items", url: PAGES.SERVICE_ORDERS.LAB_ITEMS(), requiredRole: UserRole.User }),
    ],
};

export const SCHEDULE: MenuCategory = {
    name: "Schedule",
	url: "schedule",
    options: [
        new MenuOption({ name: "ARRA", url: "/schedule/arra", requiredRole: UserRole.User }),
    ],
};
    
export const CHARGES: MenuCategory = {
    name: "Charges",
	url: "charges",
    options: [
        new MenuOption({ name: "Tracking", url: "tracking", requiredRole: UserRole.User }),
        new MenuOption({ name: "A/R Aging", url: "ar-aging", requiredRole: UserRole.User }),
        new MenuOption({ name: "Syncing", url: "syncing", requiredRole: UserRole.User }),
    ],
};

export const PO: MenuCategory = {
    name: "P.O.",
	url: "po",
    options: [
        new MenuOption({ name: "Tracking", url: "tracking", requiredRole: UserRole.User }),
        new MenuOption({ name: "Receiving", url: "receiving", requiredRole: UserRole.User }),
        new MenuOption({ name: "Billing", url: "billing", requiredRole: UserRole.User }),
        new MenuOption({ name: "Vendors", url: "vendors", requiredRole: UserRole.User }),
        new MenuOption({ name: "Product Search", url: "product-search", requiredRole: UserRole.User }),
    ],
};

export const CERTS_AND_QC: MenuCategory = {
    name: "Certs & QC",
    url: PAGES.CERTS_AND_QC(),
    options: [
		new MenuOption({ name: "Procedures", url: PAGES.CERTS_AND_QC.OPERATIONAL_PROCEDURES(), mobileSafe: true, requiredRole: UserRole.User, icon: <Assignment/> }),
        //{ name: "Tracking", url: "tracking", requiredRole: UserRole.User },
        //{ name: "Calibration", url: "calibration", requiredRole: UserRole.User },
        //{ name: "Vehicles", url: "vehicles", requiredRole: UserRole.User },
        //{ name: "Complaints", url: "complaints", requiredRole: UserRole.User },
        //{ name: "Preventative", url: "preventative", requiredRole: UserRole.User },
        //{ name: "Feedback", url: "feedback", requiredRole: UserRole.User },
    ],
};
    
export const MANAGER: MenuCategory = {
    name: "Manager",
    url: PAGES.MANAGE(),
    options: [
        new MenuOption({ name: "Scorecard", url: PAGES.MANAGE.SCORE_CARD(), requiredRole: UserRole.PowerUser}),
        new MenuOption({ name: "Messaging", url: PAGES.MANAGE.MESSAGING(), mobileSafe: true, requiredRole: UserRole.PowerUser, icon: <Send/> }),
        new MenuOption({ name: "Time Tracker", url: PAGES.MANAGE.TIME_TRACKER(), requiredRole: UserRole.PowerUser}),
        new MenuOption({ name: "Time Off Schedule", url: PAGES.MANAGE.TIME_OFF_SCHEDULE(), requiredRole: UserRole.PowerUser, icon: <CalendarMonth/>}),
        new MenuOption({ name: "Time Sheet Approval", url: PAGES.MANAGE.TIME_SHEET_APPROVAL(), requiredRole: UserRole.PowerUser, icon: <EventAvailable/>}),
        //new MenuOption({ name: "Applicant Tracker", url: PAGES.MANAGE.APPLICANT_TRACKER(),  requiredRole: UserRole.PowerUser,}),
        //{ name: "Team", url: "team", requiredRole: UserRole.User },
        //{ name: "Time Management", url: "time", requiredRole: UserRole.User },
        //{ name: "Request Management", url: "requests", requiredRole: UserRole.User },
        //{ name: "Expense Management", url: "expenses", requiredRole: UserRole.User },
    ] ,
};

export const USER: MenuCategory = {
    name: "User",
    url: "/user",
    options: [
        new MenuOption({ name: "My Profile", url: "/user/profile", mobileSafe: true, requiredRole: UserRole.User }),
        new MenuOption({ name: "Time Off", url: "/user/time-off", mobileSafe: true, requiredRole: UserRole.User }),
        new MenuOption({ name: "Hours Worked", url: "/user/time-sheets", mobileSafe: true, requiredRole: UserRole.User }),
        new MenuOption({ name: "Expense Reports", url: "/user/expense-reports", mobileSafe: true, requiredRole: UserRole.User }),
        new MenuOption({ name: "Work Times", url: "/user/work-times", mobileSafe: true, requiredRole: UserRole.User }),
        new MenuOption({ name: "Settings", url: "/user/settings", mobileSafe: true, requiredRole: UserRole.User }),
    ]
};


export const ADMIN: MenuCategory = {
    name: "Admin",
    url: PAGES.ADMIN(),
    options: [
        new MenuOption({ name: "Products", url: PAGES.ADMIN.PRODUCTS(), requiredRole: UserRole.Admin}),
        new MenuOption({ name: "Inventory", url: PAGES.ADMIN.INVENTORY(), requiredRole: UserRole.Admin})

    ],
};
